/* tslint:disable */
/* eslint-disable */
/**
 * RAISE Events API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContentLoadedV1
 */
export interface ContentLoadedV1 {
    /**
     * 
     * @type {number}
     * @memberof ContentLoadedV1
     */
    courseId: number;
    /**
     * 
     * @type {string}
     * @memberof ContentLoadedV1
     */
    impressionId: string;
    /**
     * 
     * @type {string}
     * @memberof ContentLoadedV1
     */
    sourceUri: string;
    /**
     * 
     * @type {number}
     * @memberof ContentLoadedV1
     */
    timestamp: number;
    /**
     * 
     * @type {string}
     * @memberof ContentLoadedV1
     */
    eventname: ContentLoadedV1EventnameEnum;
    /**
     * 
     * @type {string}
     * @memberof ContentLoadedV1
     */
    contentId: string;
    /**
     * 
     * @type {string}
     * @memberof ContentLoadedV1
     */
    variant: string;
}


/**
 * @export
 */
export const ContentLoadedV1EventnameEnum = {
    ContentLoadedV1: 'content_loaded_v1'
} as const;
export type ContentLoadedV1EventnameEnum = typeof ContentLoadedV1EventnameEnum[keyof typeof ContentLoadedV1EventnameEnum];


/**
 * Check if a given object implements the ContentLoadedV1 interface.
 */
export function instanceOfContentLoadedV1(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "courseId" in value;
    isInstance = isInstance && "impressionId" in value;
    isInstance = isInstance && "sourceUri" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "eventname" in value;
    isInstance = isInstance && "contentId" in value;
    isInstance = isInstance && "variant" in value;

    return isInstance;
}

export function ContentLoadedV1FromJSON(json: any): ContentLoadedV1 {
    return ContentLoadedV1FromJSONTyped(json, false);
}

export function ContentLoadedV1FromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentLoadedV1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'courseId': json['course_id'],
        'impressionId': json['impression_id'],
        'sourceUri': json['source_uri'],
        'timestamp': json['timestamp'],
        'eventname': json['eventname'],
        'contentId': json['content_id'],
        'variant': json['variant'],
    };
}

export function ContentLoadedV1ToJSON(value?: ContentLoadedV1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'course_id': value.courseId,
        'impression_id': value.impressionId,
        'source_uri': value.sourceUri,
        'timestamp': value.timestamp,
        'eventname': value.eventname,
        'content_id': value.contentId,
        'variant': value.variant,
    };
}

