/* tslint:disable */
/* eslint-disable */
/**
 * RAISE Events API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Response } from './Response';
import {
    ResponseFromJSON,
    ResponseFromJSONTyped,
    ResponseToJSON,
} from './Response';

/**
 * 
 * @export
 * @interface IbPsetProblemAttemptedV1
 */
export interface IbPsetProblemAttemptedV1 {
    /**
     * 
     * @type {number}
     * @memberof IbPsetProblemAttemptedV1
     */
    courseId: number;
    /**
     * 
     * @type {string}
     * @memberof IbPsetProblemAttemptedV1
     */
    impressionId: string;
    /**
     * 
     * @type {string}
     * @memberof IbPsetProblemAttemptedV1
     */
    sourceUri: string;
    /**
     * 
     * @type {number}
     * @memberof IbPsetProblemAttemptedV1
     */
    timestamp: number;
    /**
     * 
     * @type {string}
     * @memberof IbPsetProblemAttemptedV1
     */
    eventname: IbPsetProblemAttemptedV1EventnameEnum;
    /**
     * 
     * @type {string}
     * @memberof IbPsetProblemAttemptedV1
     */
    contentId: string;
    /**
     * 
     * @type {string}
     * @memberof IbPsetProblemAttemptedV1
     */
    variant: string;
    /**
     * 
     * @type {string}
     * @memberof IbPsetProblemAttemptedV1
     */
    problemType: string;
    /**
     * 
     * @type {Response}
     * @memberof IbPsetProblemAttemptedV1
     */
    response: Response;
    /**
     * 
     * @type {boolean}
     * @memberof IbPsetProblemAttemptedV1
     */
    correct: boolean;
    /**
     * 
     * @type {number}
     * @memberof IbPsetProblemAttemptedV1
     */
    attempt: number;
    /**
     * 
     * @type {boolean}
     * @memberof IbPsetProblemAttemptedV1
     */
    finalAttempt: boolean;
    /**
     * 
     * @type {string}
     * @memberof IbPsetProblemAttemptedV1
     */
    psetContentId: string;
    /**
     * 
     * @type {string}
     * @memberof IbPsetProblemAttemptedV1
     */
    psetProblemContentId: string;
}


/**
 * @export
 */
export const IbPsetProblemAttemptedV1EventnameEnum = {
    IbPsetProblemAttemptedV1: 'ib_pset_problem_attempted_v1'
} as const;
export type IbPsetProblemAttemptedV1EventnameEnum = typeof IbPsetProblemAttemptedV1EventnameEnum[keyof typeof IbPsetProblemAttemptedV1EventnameEnum];


/**
 * Check if a given object implements the IbPsetProblemAttemptedV1 interface.
 */
export function instanceOfIbPsetProblemAttemptedV1(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "courseId" in value;
    isInstance = isInstance && "impressionId" in value;
    isInstance = isInstance && "sourceUri" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "eventname" in value;
    isInstance = isInstance && "contentId" in value;
    isInstance = isInstance && "variant" in value;
    isInstance = isInstance && "problemType" in value;
    isInstance = isInstance && "response" in value;
    isInstance = isInstance && "correct" in value;
    isInstance = isInstance && "attempt" in value;
    isInstance = isInstance && "finalAttempt" in value;
    isInstance = isInstance && "psetContentId" in value;
    isInstance = isInstance && "psetProblemContentId" in value;

    return isInstance;
}

export function IbPsetProblemAttemptedV1FromJSON(json: any): IbPsetProblemAttemptedV1 {
    return IbPsetProblemAttemptedV1FromJSONTyped(json, false);
}

export function IbPsetProblemAttemptedV1FromJSONTyped(json: any, ignoreDiscriminator: boolean): IbPsetProblemAttemptedV1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'courseId': json['course_id'],
        'impressionId': json['impression_id'],
        'sourceUri': json['source_uri'],
        'timestamp': json['timestamp'],
        'eventname': json['eventname'],
        'contentId': json['content_id'],
        'variant': json['variant'],
        'problemType': json['problem_type'],
        'response': ResponseFromJSON(json['response']),
        'correct': json['correct'],
        'attempt': json['attempt'],
        'finalAttempt': json['final_attempt'],
        'psetContentId': json['pset_content_id'],
        'psetProblemContentId': json['pset_problem_content_id'],
    };
}

export function IbPsetProblemAttemptedV1ToJSON(value?: IbPsetProblemAttemptedV1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'course_id': value.courseId,
        'impression_id': value.impressionId,
        'source_uri': value.sourceUri,
        'timestamp': value.timestamp,
        'eventname': value.eventname,
        'content_id': value.contentId,
        'variant': value.variant,
        'problem_type': value.problemType,
        'response': ResponseToJSON(value.response),
        'correct': value.correct,
        'attempt': value.attempt,
        'final_attempt': value.finalAttempt,
        'pset_content_id': value.psetContentId,
        'pset_problem_content_id': value.psetProblemContentId,
    };
}

